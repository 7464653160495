<template>
  <MyAccountCheckAuthLoggedIn>
    <MaterialsGrid
      class="e_store-container"
      :fetch-route="wishlistMaterialRoute"
      :wishlist="true"
    />
  </MyAccountCheckAuthLoggedIn>
</template>
<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
const { url } = useDomainHost()
const { t } = useI18n()
const wishlistMaterialRoute = getAllRoutes().materialsRoutes.wishlistMaterialByServer
const { store } = useDomainState()

useServerSeoMeta(
  useOgMeta(
    url + '/my-account/wishlist',
    t('wishlist'),
    store.value?.about,
    store.value?.thumb
  )
)

useHead({
  title: t('wishlist'),
  meta: [
    { hid: 'description', name: 'description', content: store.value?.about }
  ],
  link: [useSeoCanonical(url + '/my-account/wishlist')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('profile'), url + '/my-account'),
      useSeoBreadcrumbItem(3, t('wishlist'), url + '/my-account/wishlist')
    ])
  ]
})
</script>
